/* You can add global styles to this file, and also import other style files */
/* TODO(Rusty): this should be merged in main.css*/

/***** REPORTS *****/
@import "@mescius/activereportsjs/styles/ar-js-ui.css";
@import "@mescius/activereportsjs/styles/ar-js-viewer.css";
@import "@mescius/activereportsjs/styles/ar-js-designer.css";
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/idea.css";
@import "~codemirror/theme/base16-light.css";
@import "~codemirror/addon/fold/foldgutter.css";
@import "~codemirror/addon/lint/lint.css";

@import "node_modules/wavelength-ui/styles/styles.scss";


.from-base-config {
  //border: 2px solid yellowgreen !important;
}

.removed-base-config {
  border: 2px solid red !important;
}
